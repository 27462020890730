/* eslint-disable */

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:57d6744e-5d0d-48a9-a7a4-a7bdabc43048",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_kWrnmYTZC",
    "aws_user_pools_web_client_id": "3rckjheki8pjegqre694ugki0h",
    "oauth": {
        "domain": "softwea-demo-dspis.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://demo.dsps.softwea.com/login/",
        "redirectSignOut": "https://demo.dsps.softwea.com/login/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
